import React from 'react'
import ServiceSidebardm from './ServiceSidebardm'
import detailsdm from '../../assets/images/services/services-detailsdm.jpg'
import project2 from '../../assets/images/projects/project2.jpg'


const ServiceDetailsContentdm = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={detailsdm} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Data Management </span>
                            <h3>About this Service</h3>
                            <p>Need a better way to manage your data, but can't see your way through the fog of industry jargon?</p>
                            <p>We help your complicated data system adhere to your business objectives and better your bottom line.</p>
                            <p>You can easily collect data, but, managing, organizing, and utilizing that data can make or break the bottom line.</p>
                            <p>As data management consultants, we know the data management consulting sector is full of buzzwords such as <b> big data, master data management strategy, ETL, data cleansing, real-time data, in-memory data, agile data, </b> and more.</p>
                            <p>The reality is that there are complex challenges with today's diverse data & business processes using them; no longer does a data management strategy consist of simply having the data.</p>
                            <p>Common data management challenges:</p>
                            <li>Online information</li>
                            <li>Company mergers and acquisitions</li>
                            <li>Changing ERP sources</li>
                            <li>Unstructured data analysis and exploration</li>
                            
                            <p>These issues are not going away and will not be easier to deal with in the future.
                            Diamonds Consulting  knows the environment your business is facing and we are ready to deliver data management consulting that fits into your long-term plan.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>10+ Implementations</li>
                                            <li>8+ Industry Experts</li>
                                            <li>20+ years of experience </li>
                                           

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Diamonds Consulting provides expert data strategy & information management consulting services in the areas of <b> cloud data management, enterprise data strategy, data warehouse modeling & data warehousing, data mining, data analysis, data migration, big data consulting, data cleansing, master data management</b>, and <b> data integration </b>services.</p>
                            <p>Our goal for data management consulting is to ensure decision makers have accurate and relevant information to make fact-based decisions.</p>
                            <h3>Application Areas</h3>
                            <p> Related Industries/Application Domains</p>
                            <div className="row">
                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-hospital"></i>
                                    </div>
                                        Insurance
                                    </div>
                            </div>



                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-investment"></i>
                                    </div>
                                        Logistics
                                    </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-house"></i>
                                    </div>
                                        Manufacturing
                                    </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-house"></i>
                                    </div>
                                        Health Care

                                    </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-factory"></i>
                                    </div>
                                        Banking
                                    </div>
                            </div>
                            <div className="col-lg-5 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-tracking"></i>
                                    </div>
                                        Telecommunications
                                    </div>
                            </div>

                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>Oracle</span></li>
                                <li><span>Teradata</span></li>
                                <li><span>DB2</span></li>
                                <li><span>Netezza</span></li>
                                <li><span>MS Azure</span></li>
                                <li><span>AWS</span></li>
                                <li><span>Tableau</span></li>
                                <li><span>OAC</span></li>
                                <li><span>Qlikview</span></li>
                                <li><span>Microstrategy</span></li>
                                <li><span>BusinessObjects</span></li>
                                <li><span>MS Power BI</span></li>
                                <li><span>Informatica</span></li>
                                <li><span>DataStage</span></li>
                                <li><span>ODI</span></li>
                                <li><span>Teradata Utilities</span></li>
                               
                            </ul>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebardm />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentdm