import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentdm from '../components/ServiceDetailsdm/ServiceDetailsContentdm'
import RelatedServicesdm from '../components/ServiceDetailsdm/RelatedServicesdm'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Data Management  "
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContentdm />
            <RelatedServicesdm />
            <Footer />
        </Layout>
    );
}

export default Details